import React, {useMemo, useState} from 'react';
import {Container} from "./styled";
import {EmailTemplatesRow, TemplatesHeader, TemplatesInner} from "../../styled";
import {Button, Link, NoData, WithPermissions, MobilePushStatisticsModal, ViewMobilePushTemplateModal} from "@components";
import {Paths, UserPermissions} from "@constants";
import LargeTemplateItem from "../../../../../../components/ui/LargeTemplateItem/LargeTemplateItem";
import {RioService} from "../../../../../../services";
import {archiveMobilePushTemplate, getMobilePushTemplates, getSingleMobilePushTemplate} from "@store/actions/creators";
import {useDispatch, useSelector} from "react-redux";
import {templateMobilePushSelector} from "@store/selectors";
import { useNavigate } from "react-router";
import {useModalState, usePermissions, useTranslation} from "@hooks";
import {notify} from "@utils";

const MobilePushSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const viewMobilePushModal = useModalState();
  const mobilePushes = useSelector(templateMobilePushSelector);
  const [statisticMobilePushId, setMobilePushStatisticsId] = useState(null);
  const statisticsMobilePushModal = useModalState();
  const { p, t } = useTranslation('dashboard_page');
  const [mobilePushViewItem, setMobilePushViewItem] = useState({});

  const shouldRender = useMemo(() => {
    return !!mobilePushes.data?.filter(t => t.status !== 3)?.length;
  }, [mobilePushes.data])

  const handleCreateMobilePushTemplateClick = () => {
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/new?f=default`);
    RioService.track('templates_dash');
  }

  const handleViewMobilePushPush = (data) => {
    setMobilePushViewItem({ ...data.languages[0], image: data.languages[0]?.image?.url });
    viewMobilePushModal.open();
  }

  const handleEditMobilePush = (id, used) => {
    if (used !== 1) {
      navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/${id}?view=1&f=default`)
    }
  }

  const handleDuplicateMobilePush = (id) => {
    dispatch(getSingleMobilePushTemplate({ id }));
    navigate(`${Paths.TEMPLATES_MOBILE_PUSH}/duplicate?f=default`)
  }

  const handleMobilePushArchive = (id, name) => {
    dispatch(archiveMobilePushTemplate({
      ids: [id],
      onSuccess: () => {
        notify('success', t('templates.mobile_push_archived'), name);
        dispatch(getMobilePushTemplates({ page: 1, perPage: 6, all: true }));
      },
    }));
  }

  const handleMobilePushStatisticsClick = (id) => {
    setMobilePushStatisticsId(id);
    statisticsMobilePushModal.open();
  };

  const handleCloseMobilePushEmailModal = () => {
    setMobilePushViewItem({});
    viewMobilePushModal.close();
  };

  return (
    <Container data-testid="dashboard-templates-section-mobile-push">
      <TemplatesInner style={{ marginTop: 10, paddingBottom: 0, pointerEvents: !!mobilePushes?.meta?.total ? 'auto' : 'none', minHeight: '246px' }}>
        <TemplatesHeader>
          <Link to={`${Paths.TEMPLATES_MOBILE_PUSHES}/default`} icon>{p('mobile_push')}</Link>
          <WithPermissions name={UserPermissions.CREATE_MOBILE_PUSH}>
            <Button
              style={{
                padding: '6px 14px',
                height: '26px',
                fontSize: '12px',
                lineHeight: '14px'
              }}
              testId="dashboard-templates-section-mobile-push-create"
              width="none"
              onClick={() => handleCreateMobilePushTemplateClick(true)}
            >
              {t('actions.create')}
            </Button>
          </WithPermissions>
        </TemplatesHeader>
        <EmailTemplatesRow $empty={!(!!mobilePushes.data?.filter(t => t.status !== 3)?.length)}>
          {!(!!mobilePushes.data?.filter(t => t.status !== 3)?.length) && <NoData style={{ height: 'auto', width: 'auto' }} />}
          {mobilePushes.data?.filter(t => t.status !== 3)?.slice(0, 4).map(({
            id,
            name,
            created_at,
            status,
            workflows,
            workers,
            image,
            used,
            message,
            title,
            languages,
            ...rest
          }, i) => (
            <LargeTemplateItem
              type="mobile_push"
              key={id}
              used={used}
              id={id}
              status={status}
              testId={`mobile-push-templates-item-${i}`}
              workflows={workers}
              image_url={languages[0].image?.url}
              view={!permissions.allowed(UserPermissions.UPDATE_MOBILE_PUSH)}
              name={name}
              title={name}
              date={created_at}
              body={message}
              fromDashboard
              onArchive={() => handleMobilePushArchive(id, name)}
              onStatisticsClick={() => handleMobilePushStatisticsClick(id)}
              onViewClick={() => handleViewMobilePushPush({ id, name, message, languages, title, image, workflows: workers })}
              onEditClick={() => handleEditMobilePush(id, !!workers?.length)}
              onDuplicateClick={() => handleDuplicateMobilePush(id)}
            />
          ))}
        </EmailTemplatesRow>
      </TemplatesInner>
      <ViewMobilePushTemplateModal
        data={mobilePushViewItem}
        opened={viewMobilePushModal.opened}
        onClose={handleCloseMobilePushEmailModal}
      />
      <MobilePushStatisticsModal id={statisticMobilePushId} opened={statisticsMobilePushModal.opened} onClose={statisticsMobilePushModal.close} />
    </Container>
  )
}

export default React.memo(MobilePushSection);
