import React, {useMemo, useState} from 'react';
import {Container} from "./styled";
import {SmsTemplatesRow, TemplatesHeader, TemplatesInner} from "../../styled";
import {Button, Link, WithPermissions, SmsStatisticsModal} from "@components";
import {Paths, UserPermissions} from "@constants";
import {SmallTemplateItem} from "@components/ui/SmallTemplateItem";
import {RioService} from "../../../../../../services";
import {useModalState, useTranslation} from "@hooks";
import {archiveSmsTemplate, getSingleSmsTemplate, getSmsTemplates, setSmsDuplicate} from "@store/actions/creators";
import {notify} from "@utils";
import {useDispatch, useSelector} from "react-redux";
import {templateSmsSelector} from "@store/selectors";
import { useNavigate } from "react-router";

const SmsSection = () => {
  const { p, t } = useTranslation('dashboard_page');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statisticsSmsModal = useModalState();
  const [statisticSmsId, setSmsStatisticsId] = useState(null);
  const smsTemplates = useSelector(templateSmsSelector);

  const shouldRender = useMemo(() => {
    return !!smsTemplates.data?.filter(t => t.status !== 3)?.length;
  }, [smsTemplates.data])

  const handleCreateSMSTemplateClick = () => {
    RioService.track('templates_dash');
    navigate(`${Paths.TEMPLATES_SMS}/new`);
  };

  const handleSmsStatisticsClick = (id) => {
    setSmsStatisticsId(id);
    statisticsSmsModal.open();
  };

  const handleSmsArchive = (id, name) => {
    dispatch(archiveSmsTemplate({
      id,
      onSuccess: () => {
        notify('success', t('templates.template_archived'), name);
        dispatch(getSmsTemplates({ page: 1, perPage: 6, all: true }));
      }
    }))
  }

  const handleViewSms = (id) => {
    navigate(`${Paths.TEMPLATES_SMS}/${id}?view=1`);
  };

  const handleEditSms = (id) => {
    navigate(`${Paths.TEMPLATES_SMS}/${id}`);
  };

  const handleDuplicateSms = (id) => {
    dispatch(getSingleSmsTemplate({
      id,
      onSuccess: (data) => {
        dispatch(setSmsDuplicate({ data }))
        navigate(`${Paths.TEMPLATES_SMS}/duplicate`);
      }
    }));
  
  };

  return (
    <Container data-testid="dashboard-templates-section-sms">
      {shouldRender ? (
        <TemplatesInner style={{ marginBottom: 14, paddingBottom: 0 }}>
          <TemplatesHeader>
            <Link to={`${Paths.TEMPLATES_SMSES}/default`} icon>{p('sms')}</Link>
            <WithPermissions name={UserPermissions.CREATE_SMS}>
              <Button
                style={{
                  padding: '6px 14px',
                  height: '26px',
                  fontSize: '12px',
                  lineHeight: '14px'
                }}
                testId="dashboard-templates-section-sms-create"
                width="none"
                onClick={handleCreateSMSTemplateClick}
              >
                {t('actions.create')}
              </Button>
            </WithPermissions>
          </TemplatesHeader>
          <SmsTemplatesRow>
            {smsTemplates.data?.filter(t => t.status !== 3)?.slice(0, 3).map(({ id, name, used, created_at, workers, status }, i, arr) => (
              <SmallTemplateItem
                key={id}
                small
                used={used}
                type="sms"
                fromDashboard
                status={status}
                testId={`sms-templates-item-${i}`}
                onStatisticsClick={() => handleSmsStatisticsClick(id)}
                onDeleteClick={() => {}}
                onArchive={() => handleSmsArchive(id, name)}
                title={name}
                style={{ ...(i === (arr.length - 1) ? { marginRight: '0px' } : {})}}
                workflows={workers}
                date={created_at}
                onViewClick={() => handleViewSms(id)}
                onEditClick={() => handleEditSms(id)}
                onDuplicateClick={() => handleDuplicateSms(id)}
              />
            ))}
          </SmsTemplatesRow>
        </TemplatesInner>
      ) : null}
      <SmsStatisticsModal id={statisticSmsId} opened={statisticsSmsModal.opened} onClose={statisticsSmsModal.close} />
    </Container>
  )
}

export default React.memo(SmsSection);
