import { TemplatesActionTypes } from '../types';
import { asCreator, withPagination, withCallbacks, withOrdering, withFilters } from '../../lib';
import { compose } from '@utils';

export const getSmsTemplates = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({
    type: TemplatesActionTypes.GET_SMS,
    payload: { folder_id, all },
  }))
);

export const loadTemplatesLinks = compose(
  withCallbacks,
  asCreator(({ links }) => ({
    type: TemplatesActionTypes.LOAD_TEMPLATES_LINK,
    payload: { links },
  }))
);

export const createSmsTemplate = compose(
  withCallbacks,
  asCreator(({ id, name, meta, languages, app_integration_id, links, folder_id }) => ({
    type: TemplatesActionTypes.CREATE_SMS,
    payload: { id, name, meta, languages, app_integration_id, links, folder_id },
  }))
);

export const getSingleViber = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_SINGLE_VIBER,
    payload: { id },
    meta: { id },
  }))
)

export const createViberTemplate = compose(
  withCallbacks,
  asCreator((data) => ({
    type: TemplatesActionTypes.CREATE_VIBER,
    payload: { ...data },
  }))
)

export const setNewWebpush = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_NEW_WEBPUSH,
    payload: { data },
  }))
)

export const setNewMobilePush = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_NEW_MOBILE_PUSH,
    payload: { data },
  }))
)

export const setNewViber = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_NEW_VIBER,
    payload: { data },
  }))
)

export const getViberTemplates = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({
    type: TemplatesActionTypes.GET_VIBERS,
    payload: { folder_id, all },
  }))
);

export const getViberSummary = compose(
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_VIBER_SUMMARY,
    payload: { id },
  }))
);

export const getMobilePushSummary = compose(
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_MOBILE_PUSH_SUMMARY,
    payload: { id },
  }))
);

export const deleteSmsTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.DELETE_SMS,
    payload: { id },
    meta: { id },
  }))
);

export const getSingleSmsTemplate = asCreator(({ id }) => ({
  type: TemplatesActionTypes.GET_SINGLE_SMS,
  payload: { id },
  meta: { id },
}));

export const clearSingleSms = () => ({
  type: TemplatesActionTypes.CLEAR_SINGLE_SMS,
});

export const editSmsTemplate = compose(
  withCallbacks,
  asCreator(({ id, name, languages, meta, app_integration_id, links, folder_id }) => ({
    type: TemplatesActionTypes.EDIT_SMS,
    payload: { id, name, languages, meta, app_integration_id, links, folder_id },
  }))
);

export const getEmailTemplates = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({
    type: TemplatesActionTypes.GET_EMAILS,
    payload: { folder_id, all }
  }))
);

export const getMobilePushTemplates = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({
    type: TemplatesActionTypes.GET_MOBILE_PUSH,
    payload: { folder_id, all }
  }))
);

export const clearSingleMobilePush = () => ({
  type: TemplatesActionTypes.CLEAR_SINGLE_MOBILE_PUSH,
});

export const createEmailTemplate = compose(
  withCallbacks,
  asCreator(({ name, app_integration_id, folder_id, meta, languages, driver, links, email_fields, group_id }) => ({
    type: TemplatesActionTypes.CREATE_EMAIL,
    payload: { name, app_integration_id, meta, languages, driver, links, email_fields, group_id, folder_id },
  }))
);

export const getEmailSampleData = compose(
  asCreator(({ type }) => ({
    type: TemplatesActionTypes.GET_EMAIL_SAMPLE_DATA,
    payload: { type },
  }))
)

export const deleteMobilePushTemplate = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: TemplatesActionTypes.DELETE_MOBILE_PUSH,
    payload: { ids },
  }))
);

export const deleteEmailTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.DELETE_EMAIL,
    payload: { id },
  }))
);

export const getSingleEmailTemplate = asCreator(({ id }) => ({
  type: TemplatesActionTypes.GET_SINGLE_EMAIL,
  payload: { id },
  meta: { id },
}));

export const setSingleEmailTemplate = asCreator((data) => ({
  type: TemplatesActionTypes.SET_SINGLE_EMAIL,
  payload: data,
}));

export const editEmailTemplate = compose(
  withCallbacks,
  asCreator(({ id, name, app_integration_id, meta, languages, driver, links, folder_id, email_fields, group_id }) => ({
    type: TemplatesActionTypes.EDIT_EMAIL,
    payload: { id, name, app_integration_id, meta, languages, driver, links, email_fields, group_id, folder_id },
  }))
);

export const validateEmailTemplateName = compose(
  withCallbacks,
  asCreator(({ name, id }) => ({
    type: TemplatesActionTypes.VALIDATE_EMAIL_TEMPLATE_NAME,
    payload: { name, id },
  }))
);

export const validateWebpushTemplateName = compose(
  withCallbacks,
  asCreator(({ name, id }) => ({
    type: TemplatesActionTypes.VALIDATE_WEBPUSH_TEMPLATE_NAME,
    payload: { name, id },
  }))
)

export const validateWebhookTemplateName = compose(
  withCallbacks,
  asCreator(({ name, id }) => ({
    type: TemplatesActionTypes.VALIDATE_WEBHOOK_TEMPLATE_NAME,
    payload: { name, id },
  }))
)

export const validateSmsTemplateName = compose(
  withCallbacks,
  asCreator(({ name, id }) => ({
    type: TemplatesActionTypes.VALIDATE_SMS_TEMPLATE_NAME,
    payload: { name, id },
  }))
);

export const setMobilePushDuplicate = ({ data }) => ({
  type: TemplatesActionTypes.SET_MOBILE_PUSH_DUPLICATE,
  payload: { data },
});

export const setEmailDuplicate = ({ data }) => ({
  type: TemplatesActionTypes.SET_EMAIL_DUPLICATE,
  payload: { data },
});

export const setSmsDuplicate = ({ data }) => ({
  type: TemplatesActionTypes.SET_SMS_DUPLICATE,
  payload: { data },
});

export const setNewSms = ({ data }) => ({
  type: TemplatesActionTypes.SET_NEW_SMS,
  payload: { data },
});

export const setNewEmail = ({ data }) => ({
  type: TemplatesActionTypes.SET_NEW_EMAIL,
  payload: { data },
});

export const getSingleWebpushTemplate = compose(
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_SINGLE_WEBPUSH,
    meta: { id }
  }))
);

export const getSingleMobilePushTemplate = compose(
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_SINGLE_MOBILE_PUSH,
    meta: { id }
  }))
);

export const getWebpushTemplates = compose(
  withPagination,
  withOrdering,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({ type: TemplatesActionTypes.GET_WEBPUSH, payload: { folder_id, all } }))
);

export const deleteWebpush = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.DELETE_WEBPUSH,
    meta: { id },
  }))
);

export const createMobilePush = compose(
  withCallbacks,
  asCreator(({ body }) => ({
    type: TemplatesActionTypes.CREATE_MOBILE_PUSH,
    payload: { body }
  }))
);

export const createWebpush = compose(
  withCallbacks,
  asCreator(({ body }) => ({
    type: TemplatesActionTypes.CREATE_WEBPUSH,
    payload: { body }
  }))
);

export const getApiRequestTemplates = compose(
  withPagination,
  withFilters(['name', 'start_date', 'end_date', 'status']),
  asCreator(({ folder_id, all }) => ({
    type: TemplatesActionTypes.GET_API_REQUEST,
    payload: { folder_id, all },
  }))
);

export const createApiRequest = compose(
  withCallbacks,
  asCreator(({ body }) => ({
    type: TemplatesActionTypes.CREATE_API_REQUEST,
    payload: { body },
  }))
);

export const getSingleApiRequest = compose(
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.GET_SINGLE_API_REQUEST,
    meta: { id },
  }))
);

export const deleteApiRequest = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.DELETE_API_REQUEST,
    meta: { id },
  }))
);

export const getTemplatePreviewInfo = compose(
  withCallbacks,
  asCreator(({ fields, customer_id }) => ({
    type: TemplatesActionTypes.GET_TEMPLATE_PREVIEW_INFO,
    payload: { fields, customer_id },
  }))
);

export const clearApiRequestPreview = compose(
  asCreator(() => ({ type: TemplatesActionTypes.CLEAR_API_REQUEST_PREVIEW }))
);

export const sendTestEmail = compose(
  withCallbacks,
  asCreator(({ email_id, emails, app_integration_id, subject, bcc, body, design, driver, exampleData, fake }) => ({
    type: TemplatesActionTypes.SEND_TEST_EMAIL,
    payload: { email_id, app_integration_id, subject, bcc, data: body, design, driver, emails, exampleData, fake }
  }))
);

export const sendTestSms = compose(
  withCallbacks,
  asCreator(({ sms_id, phones, message, app_integration_id, exampleData, fake }) => ({
    type: TemplatesActionTypes.SEND_TEST_SMS,
    payload: { sms_id, phones, message, app_integration_id, exampleData, fake }
  }))
);

export const setEmailPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_EMAIL_PREVIEW,
    payload: { data },
  }))
);

export const setViberPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_VIBER_PREVIEW,
    payload: { data },
  }))
)

export const setSmsPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_SMS_PREVIEW,
    payload: { data },
  }))
);

export const setWebpushPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_WEBPUSH_PREVIEW,
    payload: { data },
  }))
);

export const setMobilePushPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_MOBILE_PUSH_PREVIEW,
    payload: { data },
  }))
);

export const setApiRequestPreview = compose(
  asCreator((data) => ({
    type: TemplatesActionTypes.SET_API_REQUEST_PREVIEW,
    payload: { data },
  }))
);

export const clearEmailPreview = compose(
  asCreator(() => ({
    type: TemplatesActionTypes.CLEAR_EMAIL_PREVIEW,
  }))
);

export const clearViberPreview = compose(
  asCreator(() => ({
    type: TemplatesActionTypes.CLEAR_VIBER_PREVIEW,
  }))
);

export const clearSmsPreview = compose(
  asCreator(() => ({
    type: TemplatesActionTypes.CLEAR_SMS_PREVIEW,
  }))
);

export const clearWebpushPreview = compose(
  asCreator(() => ({
    type: TemplatesActionTypes.CLEAR_WEBPUSH_PREVIEW,
  }))
);

export const clearMobilePushPreview = compose(
  asCreator(() => ({
    type: TemplatesActionTypes.CLEAR_MOBILE_PUSH_PREVIEW,
  }))
);

export const sendTestWebPush = compose(
  withCallbacks,
  asCreator(({ subscribed, subscription, data }) => ({
    type: TemplatesActionTypes.SEND_TEST_WEB_PUSH,
    payload: { subscription, subscribed, data },
  })),
);

export const getSubscriptionGroups = compose(
  withCallbacks,
  asCreator(({ entity }) => ({
    type: TemplatesActionTypes.GET_SUBSCRIPTION_GROUPS,
    meta: { entity }
  })),
);

export const clearSingleEmail = () => ({
  type: TemplatesActionTypes.CLEAR_SINGLE_EMAIL,
});

export const clearSingleWebpush = () => ({
  type: TemplatesActionTypes.CLEAR_SINGLE_WEBPUSH,
});

export const clearSingleViber = () => ({
  type: TemplatesActionTypes.CLEAR_SINGLE_VIBER,
});

export const archiveViberTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.ARCHIVE_VIBER_TEMPLATE,
    payload: { id },
  }))
);

export const deleteViberTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.DELETE_VIBER,
    payload: { id },
  }))
);

export const unarchiveViberTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.UNARCHIVE_VIBER_TEMPLATE,
    payload: { id },
  }))
)

export const archiveEmailTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.ARCHIVE_EMAIL_TEMPLATE,
    payload: { id },
  }))
);

export const unarchiveEmailTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.UNARCHIVE_EMAIL_TEMPLATE,
    payload: { id },
  }))
);

export const archiveMobilePushTemplate = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: TemplatesActionTypes.ARCHIVE_MOBILE_PUSH_TEMPLATE,
    payload: { ids },
  }))
);

export const unarchiveMobilePushTemplate = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: TemplatesActionTypes.UNARCHIVE_MOBILE_PUSH_TEMPLATE,
    payload: { ids },
  }))
);

export const archiveSmsTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.ARCHIVE_SMS_TEMPLATE,
    payload: { id },
  }))
);

export const unarchiveSmsTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.UNARCHIVE_SMS_TEMPLATE,
    payload: { id },
  }))
);

export const archiveWebpushTemplate = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: TemplatesActionTypes.ARCHIVE_WEBPUSH_TEMPLATE,
    payload: { ids },
  }))
);

export const unarchiveWebpushTemplate = compose(
  withCallbacks,
  asCreator(({ ids }) => ({
    type: TemplatesActionTypes.UNARCHIVE_WEBPUSH_TEMPLATE,
    payload: { ids },
  }))
);

export const archiveWebhookTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.ARCHIVE_WEBHOOK_TEMPLATE,
    payload: { id },
  }))
);

export const unarchiveWebhookTemplate = compose(
  withCallbacks,
  asCreator(({ id }) => ({
    type: TemplatesActionTypes.UNARCHIVE_WEBHOOK_TEMPLATE,
    payload: { id },
  })),
);

export const getFolders = compose(
  withCallbacks,
  withPagination,
  asCreator(({ type }) => ({
    type: TemplatesActionTypes.GET_FOLDERS,
    payload: { type }
  }))
);

export const createFolder = compose(
  withCallbacks,
  asCreator(({ name, type, id }) => ({
    type: TemplatesActionTypes.CREATE_FOLDER,
    payload: { name, type, id },
  }))
);

export const setFolder = compose(
  withCallbacks,
  asCreator(({ id, folder_id, type }) => ({
    type: TemplatesActionTypes.SET_FOLDER,
    payload: { id, folder_id, type },
  }))
);

export const setEmailFolder = compose(
  withCallbacks,
  asCreator(({ id, folder_id }) => ({
    type: TemplatesActionTypes.SET_EMAIL_FOLDER,
    payload: { id, folder_id },
  }))
);

export const setViberFolder = compose(
  withCallbacks,
  asCreator(({ id, folder_id }) => ({
    type: TemplatesActionTypes.SET_VIBER_FOLDER,
    payload: { id, folder_id },
  }))
);

export const setSmsFolder = compose(
  withCallbacks,
  asCreator(({ id, folder_id }) => ({
    type: TemplatesActionTypes.SET_SMS_FOLDER,
    payload: { id, folder_id },
  }))
);

export const deleteFolder = compose(
  withCallbacks,
  asCreator(({ id, type }) => ({
    type: TemplatesActionTypes.DELETE_FOLDER,
    payload: { id, type },
  }))
);

export const getFoldersList = compose(
  withCallbacks,
  asCreator(({ type }) => ({
    type: TemplatesActionTypes.GET_FOLDERS_LIST,
    payload: { type },
  }))
);

export const getTemplateStatistics = compose(
  withCallbacks,
  asCreator(({ type, ...other }) => ({
    type: TemplatesActionTypes.GET_TEMPLATE_STATISTICS,
    payload: { type, ...other },
    meta: { type },
  }))
);
